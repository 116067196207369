import React, { useEffect, useState } from "react"
import "./press-release.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Cross from "../../images/home/overcoming/cross.svg"
import { pressContent } from "./helper"
import Ali from "../../images/about/shapeTeam/ali.png"
import John from "../../images/about/shapeTeam/john.png"
import Gordon from "../../images/about/shapeTeam/gordon.jpg"
import Craig from "../../images/about/shapeTeam/craig.jpg"
import Doug from "../../images/about/shapeTeam/doug2.jpg"
import Ken from "../../images/about/shapeTeam/ken.png"
import Nicola from "../../images/about/shapeTeam/nicola.jpg"
import Share from "../../images/about/shareicon.inline.svg"
import ShareSafari from "../../images/about/share3.png"
import { isSafari } from "react-device-detect"

import Copy from "../../images/about/copy.svg"
const PressRelease = () => {
  const [isSafariBrow, setIsSafari] = useState(false)
  useEffect(() => {
    setIsSafari(isSafari)
  }, [isSafari])
  const copyLink = async () => {
    if (typeof window === "undefined") return
    let baseUrl = window?.location?.origin
    let elem = document.getElementById("copy-btn-press")
    // elem.textContent = "Copied"
    navigator.clipboard.writeText(
      `${baseUrl}/press-release/behavioural-science-start-up-launches-to-help-international-businesses-solve-workplace-productivity-puzzle/`
    )
    let shareDetails = {
      url: `${baseUrl}/press-release/behavioural-science-start-up-launches-to-help-international-businesses-solve-workplace-productivity-puzzle/`,
      title: "SHAPE",
      text: "",
    }
    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() => console.log("Content Shared Successfully!"))
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`)
      }
    } else {
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      )
    }
  }
  let tooltipElem = (location) => {
    return (
      <div
      className={`tooltipWrapperPress ${location === "bottom" ? "bottom" : ""}`}
      >
        <div className="tooltipFlex">
          <button id="copy-btn-press" onClick={copyLink}>
            Share
          </button>
          <img
            className="closeTooltip"
            src={Cross}
            alt="Close"
            onClick={() => {
              document.body.click()
            }}
          />
        </div>
      </div>
    )
  }
  return (
    <Layout>
      <SEO title="Press Release" />

      <div className="pressWrapper">
        <div className="pressHeaderFlex">
          <div className="pressPublished">
            <p>
              <strong>Published</strong>
            </p>
            <p>September 18, 2023 7.00 AM BST</p>
          </div>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 100 }}
            overlay={tooltipElem()}
            rootClose={true}
            trigger={["click", "focus"]}
          >
            <div className="pressCopyIcon">
              {isSafariBrow ? (
                <img src={ShareSafari} alt="Safari" />
              ) : (
                <Share />
              )}
            </div>
          </OverlayTrigger>
        </div>
        <h2 className="pressHeading">{pressContent?.heading}</h2>
        <p
          className="pressSubHeading"
          dangerouslySetInnerHTML={{
            __html: pressContent?.subHeading,
          }}
        />
        <hr />
        <p
          className="pressContent"
          dangerouslySetInnerHTML={{
            __html: pressContent?.content,
          }}
        />

        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg bg">
              <img src={Ali} alt="Ali" />
            </div>
            <h4>Ali Khan</h4>
            <p>Chief Executive Officer</p>
            <p>SHAPE</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: pressContent?.ali,
            }}
          />
        </div>
        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg bg john">
              <img src={John} alt="John" />
            </div>
            <h4>Dr. John Lang</h4>
            <p>Chief Scientific Officer</p>
            <p>SHAPE</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: pressContent?.john,
            }}
          />
        </div>
        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg">
              <img src={Gordon} alt="Gordon" />
            </div>
            <h4>Gordon Watson</h4>
            <p>Chairman, AXA Asia</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: pressContent?.gordon,
            }}
          />
        </div>
        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg">
              <img src={Ken} alt="Ken" />
            </div>
            <h4>Ken Hoskin</h4>
            <p>Head of APAC HR, Adyen</p>
            <p>(Formerly at Meta, Airbnb)</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: pressContent?.ken,
            }}
          />
        </div>
        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg">
              <img src={Nicola} alt="Nicola" />
            </div>
            <h4>Nicola Thompson</h4>
            <p>Non Executive Director</p>
            <p>(Formerly CEO MADE.com)</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: pressContent?.nicola,
            }}
          />
        </div>
        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg">
              <img src={Craig} alt="Craig" />
            </div>
            <h4>Craig Moss</h4>
            <p>EVP, Ethisphere</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: pressContent?.craig,
            }}
          />
        </div>
        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg">
              <img src={Doug} alt="Doug" />
            </div>
            <h4>Doug Hudson</h4>
            <p>Managing Partner, Braxton Capital</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: pressContent?.doug,
            }}
          />
        </div>
        <br />
        <hr />
        <br />
        <p
          className="pressContent about"
          dangerouslySetInnerHTML={{
            __html: pressContent?.conclusion,
          }}
        />
        <p
          className="pressContent about links"
          dangerouslySetInnerHTML={{
            __html: pressContent?.links,
          }}
        />
        <div className="pressCopyIcon bottomIcon">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 100 }}
            overlay={tooltipElem("bottom")}
            rootClose={true}
            trigger={["click", "focus"]}
          >
            {isSafariBrow ? <img src={ShareSafari} alt="Safari" /> : <Share />}
          </OverlayTrigger>
        </div>
      </div>
    </Layout>
  )
}

export default PressRelease
