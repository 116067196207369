const pressContent = {
  heading:
    "Behavioural science start-up launches to help international businesses solve workplace productivity puzzle",
  subHeading:
    "AXA Asia Chairman, global HR leader formerly with Airbnb and Meta, and MADE.com ex-CEO among five appointments to SHAPE Global’s advisory board as it plans to share the science which helps workers and businesses flourish",
  content:
    "SHAPE Global, a behavioural science start-up has launched with a pledge to improve productivity for businesses around the world impacted by a common set of workplace issues, by helping executives, managers and workers to flourish.\n\nReturn to the office, burnout, talent retention, generational gaps at work, tumbling eNPS. All these employee-centric issues are affecting the smallest enterprises, through to the largest multi-national corporates in every industry and every country.\n\nCreated by Ali Khan and Dr. John Lang, SHAPE is an employee experience and organisational impact platform.\n\nAli and John believe businesses now want more robust, evidence-backed insights that inform personal, team and company level actions to address today’s pressing workplace challenges, and achieve measurably better performance while helping people to flourish at work.\n\nThe acronym SHAPE stands for the System for Health, Attendance, Productivity and Engagement. The platform analyses factors including workplace satisfaction, engagement, culture, and health to equip every employee, manager, and executive with a personalised and actionable pathway to help them and their teams measurably flourish at work.\n\nAnd SHAPE has already won backing from a number of global business leaders, five of whom have been appointed to join SHAPE’s advisory board to expand the reach and relevance of the firm to a global audience.\n\nSHAPE’s new advisory board members are:\n\n<ul><li><b>Gordon Watson</b>, Chairman of AXA Asia</li><li><b>Ken Hoskin</b>, Vice President and Head of APAC HR, Adyen, formerly with Meta and Airbnb</li><li><b>Nicola Thompson</b>, Senior Executive, Board Advisor, and former CEO of MADE.com</li><li><b>Craig Moss</b>, Executive Vice President of Measurement at Ethisphere</li><li><b>Doug Hudson</b>, Managing Partner and Founding Member of Braxton Capital</li></ul>\n",
  ali:
    "“As the employee experience measurement company, our mission is to help business leaders, managers and individual employees flourish. We’ve developed a way to measure and report the areas people need to focus on most and get support to reach their peak performance.\n\n“SHAPE is now actively helping businesses grapple with today’s prevalent concerns like return to the office, finding balance in hybrid working, solving talent retention issues, improving mental well-being. And we believe US businesses want a more intelligent system like SHAPE to help them in instantly connecting business insights to tangible employee actions.\n\n\"The appointment of our advisory board underscores our commitment to integrate science-backed insights with real-world experiences across industries to help businesses across the globe.\n\n“I'm excited to be hosting roundtable sessions with our advisors during the course of the coming year. Business leaders can join us in this forum designed to share actionable insights and learnings that can help them address real and pressing business concerns.”",
  john:
    "“I’m very excited about the quality of the appointments to our SHAPE Advisory Board. It is a huge testament to the value these global industry leaders see in SHAPE.\n\n“The collective expertise of our new advisory board members perfectly complements our guiding mantra, <strong><i>know your people.</i></strong>\n\n“Each advisor brings a unique set of experiences, skills and their own shared goals of making a positive impact by helping businesses flourish.”",
  gordon:
    "Gordon Watson, also the Chair of the Mental Health at Work Index™, has consistently championed mental health awareness, a pursuit complimenting SHAPE's commitment to employees’ mental well-being.\n\nHe said “I am honoured to join as Senior Advisor to the SHAPE Board. Together, we are establishing a new paradigm that transcends traditional employee surveying to focus on purposeful measurement to enable flourishing. This engagement echoes my passionate advocacy for supporting workforce mental health, a domain which SHAPE is a pioneer in.”",
  ken:
    "Formerly HR leader at Meta and Airbnb Asia region, Ken is renowned for enhancing employee value and influencing commercial success. His effective methodology for nurturing talent and progression resonates deeply with SHAPE's core values.\n\n“SHAPE is pioneering the future of HR, crafting an exceptional blend of work flexibility, managerial support, and human-centric strategies, all powered by scientific insights,” says Hoskin. “The innovation SHAPE brings to the employee experience is transforming employee experiences globally. I'm looking forward to contributing to this journey.”",
  nicola:
    "Formerly COO and then CEO at MADE.com, Nicola brings a passion for technology and solving complex business challenges.\n\n“I've always believed in the impact of businesses that address real-world issues,” says Thompson. “The fervour of the SHAPE team for enhancing organisational understanding of employee experience struck me.\n\n“Data alone doesn't suffice; insightful analysis and actionable feedback are critical to success. SHAPE offers a novel solution to this Employee Experience Paradox. I'm eager to contribute to SHAPE's journey by drawing on my experiences in building and scaling businesses.”",
  craig:
    "Craig is also Executive Board Member at the Association of Professional Social Compliance Auditors Inc. (APSCA), Director, Cyber Readiness Institute and Director Digital Supply Chain Institute. He is celebrated for his accomplishments in compliance and risk management. Moss' expertise is rooted in formulating programs that drive companies and their supply chains to evaluate and enhance their ESG and compliance endeavours.\n\n“I have devoted many years to developing 'measure and improve' programs to enhance compliance and ESG maturity, on topics including social compliance, cybersecurity, DEI, and mental health in the workplace” says Moss. “I’m thrilled to work with SHAPE and apply its unique approach to influencing human behaviour to create cultures that reduce compliance risks and enhance the performance of individuals and organisations.\"",
  doug:
    "Doug brings depth in strategy, M&A, and corporate transformations.\n\nAs someone who fully comprehends SHAPE’s inherent value through science, Doug adds “Working with SHAPE’s leadership was an easy decision to make. Their unique approach to human capital management sets them apart from typical employee engagement platforms.\n\n“Their data is an accelerant to empowering new business models and propositions while delivering a distinct advantage to HR leaders and strategists. At the same time what really differentiates SHAPE is its appeal to CEOs and its goal to boost corporate and team productivity thereby leading to greater ROI. In a market hungry for such a growth catalyst, I see an exceptional opportunity for SHAPE.”",
  conclusion:
    "<b>About SHAPE:</b>\nSHAPE is the employee experience measurement company. Our mission is to help employees and companies flourish. Encompassing all the workplace topics in one succinct survey, SHAPE is the System for Health, Attendance, Productivity and Engagement. It helps companies evolve to support the ‘Total Well-being’ of their employees. Powered by statistical, clinical and behavioural science, SHAPE’s intuitive, self-serve, platform with its advanced insights allows organisations of any size to survey and support employees, managers and executives with powerful personal, team and company reporting. SHAPE helps everyone find their focus to flourish in every facet of their work.\n\n",
  links:
    'For more on SHAPE visit <a href="https://www.shapepowered.com">www.shapepowered.com</a>\n\nMedia Contact: email <a  href="mailto:press@shapepowered.com" target="_blank">press@shapepowered.com</a>',
}

export { pressContent }
